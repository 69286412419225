import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import About from '../components/about';
import CardGrid from '../components/card-grid';
import TeamGrid from '../components/team-grid';
import Contact from '../components/contact-info';
import FeaturedProjects from '../components/featured-projects';
import Hero from '../components/hero';
import Layout from '../components/layout';
import RecentPosts from '../components/recent-posts';
import SEO from '../components/seo';
import { indexMenuLinks } from '../components/_config/menu-links';
import ContactForm from '../components/contact-form';
import ReactDOM from "react-dom";
import { StyledTextSection } from '../components/_shared/styled-text-section';
import Header from '../components/header';
import { StyledFullHeightSection, StyledSection } from '../components/_shared/styled-section';

const Index = ({}) => {
  return (
    <Layout menuLinks={indexMenuLinks}>
      <StyledFullHeightSection>
        <SEO title="Rakkaton" />
          <ContactForm />
        </StyledFullHeightSection>
    </Layout>
  );
};

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);

export default Index;